@import "../../constants.scss";

.menu {
    display: flex;
    align-items: flex-end;
    margin: 0 40px;

    & a {
        margin-left: 20px;
    }

    &__button {
        white-space: nowrap;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        line-height: 25px;
        padding: 7px 20px;
        border-color: $primary-color;
        border-width: 2px;
        border-style: solid;
        border-radius: 23px;
        font-size: 15px;
        font-weight: 600;
        color: $primary-color;
        cursor: pointer;

        @media screen and (min-width: 1500px) {
            display: none;
        }
    }

    &__wide {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        @media screen and (max-width: 1500px) {
            display: none;
        }
    }

    &__dropdown {
        position: relative;
        width: 0px;
        height: 0px;
        top: 30px;
        left: -155px - 25px;
        z-index: 6; 

        &_content {
            display: flex;
            flex-direction: column;
            width: 180px + 25px;

            & a {
                margin-bottom: 10px;
            }
        }
    }
}
