.price-list {
    display: flex;
    flex: 1;
    flex-direction: column;

    img {
        width: 300px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
}
