.title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px;

    &__title {
        font-size: 48px;
        line-height: 48px;
    }

    &__subtitle {
        font-size: 30px;
        font-weight: 300;
        margin-top: 20px;
    }
}
