@import "./constants.scss";

// Box-sizing reset

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

// Stop

html, head, body {
    padding: 0px;
    margin: 0px;
}

body {
    font-family: "Bai Jamjuree", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    color: $primary-color;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}

h1, h2, h3 {
    margin: 0px;
}

h3 {
    margin: 24px 0 0 0;
}

h4 {
    margin: 16px 0 0 0;
}

p {
    margin: 8px 0;
}

h2 {
    font-size: 40px;
}

.page {
    display: flex;
    background-color: $secondary-color;
    overflow: hidden;
    flex-direction: column;
    min-height: 100vh;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content {
    padding: 20px;
    max-width: 1500px;
    min-height: 60vh;
    margin-top: 30px;

    @media screen and (min-width: 1500px) {
        margin: 0 auto;
    }
}
