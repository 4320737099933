@import "../../constants.scss";

$delimiter-color: rgba(0, 0, 0, 0.5);

.menu-item {
    white-space: nowrap;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    line-height: 25px;
    padding: 0px 20px;
    height: 46px;
    border-color: $primary-color;
    border-width: 2px;
    border-style: solid;
    border-radius: 23px;
    font-size: 15px;
    font-weight: 600;
    color: $primary-color;
    background-color: $secondary-color;
}
