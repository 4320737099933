.logo {
    &__container {
        align-self: center;
    }

    &__image {
        width: 80px;
        height: 80px;
        background-image: url("./logo.png");
        margin: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 50px;
    }
}
