@import "../../constants.scss";

.reference {
    display: flex;
    flex: 1;
    flex-direction: column;

    a {
        color: $primary-color;
    }

    &__item {
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
    }

    img {
        height: 200px;
        margin-right: 10px;
        margin-top: 10px;
    }

    &__images {
        flex-direction: row;
    }
}
