@import "../../constants.scss";

.footer {
    display: block;
    text-align: center;
    margin: 20px;
    margin-top: 100px;
    margin-bottom: 10px;

    &__link {
        font-size: 90%;
        color: $primary-color;
        text-decoration: none;
    }

    &__cookies {
        font-size: 70%;
        color: $primary-color;
    }
}
