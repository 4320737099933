.gallery {
    display: flex;
    flex: 1;
    flex-direction: column;

    &__image {
        width: 150px;
        height: 150px;
        margin-right: 30px;
        margin-top: 30px;
        object-fit: cover;
    }
}
